import Axios from "axios";
// import { useAuthStore } from "./stores/auth.store";
// import { getToken } from "./utils/authorization";
// import jwt_decode from 'jwt-decode';

// Axios.defaults.baseURL = import.meta.env.VITE_BASE_API;
const TOKEN = window.localStorage.getItem("TOKEN") || null
Axios.defaults.baseURL = process.env.VUE_APP_BASE_API
Axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
// Axios.interceptors.request.use(
//   async function (config) {
//     const decode = jwt_decode(getToken())
//     if (Date.now() >= decode.exp * 1000) {
//       await useAuthStore().refresh()
//     }
//     // Do something before request is sent
//     // config.headers.common['Authorization'] = `Bearer ${getToken()}`;
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   },
// );