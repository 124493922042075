import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

// import externalComponent from '../utils/external-component';

Vue.use(VueRouter)

// const lazyLoadVue = function (view) {
//   return () => import(`@/views/${view}.vue`)
// }

// const loadVisualization = function (view) {
//   return () => import(`@/visualization/${view}.vue`)
// }

// const baseFolder = 'http://localhost:3000/run/display/dev-tenant/demo_app/visualization/v1/'

const routes = [
  {
    path: '/:tenantname/:appname/app/login',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),

  },
  {
    path: '/:tenantname/:appname/app/:utenant/login',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),

  },
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '../views/MainView.vue'),
    children: [
      // {
      //   path: '/:tenantname/:appname/login',
      //   name: 'login',
      //   // route level code-splitting
      //   // this generates a separate chunk (login.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
      // },
      {
        path: '/:tenantname/:appname/app/:routename(.*)',
        name: 'app-content',
        component: () => import(/* webpackChunkName: "login" */ '../views/ContentView.vue')
      },
      {
        path: '/:tenantname/:appname/app',
      }
    ]
  },
  
  // {
  //   path: '/super',
  //   name: 'super',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/BaseView.vue'),
  //   children: [
  //     {
  //       path: '/super/profile',
  //       name: 'usr_profile',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/ProfileView.vue')
  //     },
  //     {
  //       path: '/appx/:routename(.*)',
  //       name: 'app_menu',
  //       component: lazyLoadVue('ContentView'),
  //       // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //     },
  //     {
  //       path: '/super/:routename(.*)',
  //       name: 'app_super',
  //       component: lazyLoadVue('ScreenView'),
  //       // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //     }      
  //   ]
  // },
  // {
  //   path: '/app',
  //   name: 'main',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/BaseViewAuth.vue'),
  //   children: [
  //     {
  //       path: '/app/profile',
  //       name: 'usr_profile',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/ProfileView.vue')
  //     },
  //     {
  //       path: '/app/:routename(.*)',
  //       name: 'app_menu',
  //       component: lazyLoadVue('ScreenViewAuth'),
  //       // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //     },
  //   ]
  // },
  // {
  //   path: '/xapp',
  //   name: 'application',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   // component: () => externalComponent(baseFolder+'system/main_screen'),
  //   children: [
  //     {
  //       path: '/xapp/*',
  //       name: 'app_menu',
  //       component: lazyLoadVue('Distrik'),
  //       // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //     },
  //   ]
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AppView.vue')
    
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
