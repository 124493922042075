import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
  themes: {
    light: {
      "background": "#ffffff",
      "surface": "#ffffff",
      "primary": "#00a3b9",
      "secondary": "#2a2894",
      "accent": "#8bc34a",
      "error": "#ff5722",
      "warning": "#ffc107",
      "info": "#03a9f4",
      "success": "#009688",
      "drawer": "#ffffff",
      "header": "#f0f0f0",
      "on-surface": "#552255",
      "on-background": "#555555",
    },
    dark: {
      "background": "#444444",
      "surface": "#222222",
      "primary": "#00a3b9",
      "secondary": "#2a2894",
      "accent": "#8bc34a",
      "error": "#ff5722",
      "warning": "#ffc107",
      "info": "#03a9f4",
      "success": "#009688",
      "drawer": "#222222",
      "header": "#222222",
      "on-surface": "#552255",
      "on-background": "#eeeeee",

    }
  },
},
})