import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia'

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
// import 'vue-toast-notification/dist/theme-default.css';
import './axios';

import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});
// console.log('Icon-Default', Icon.Default)


Vue.use(PiniaVuePlugin);
Vue.config.productionTip = false

Vue.use(VueMonacoEditorPlugin)

Vue.use(VueToast)

new Vue({
  router,
  vuetify,
  pinia: createPinia(),
  render: h => h(App)
}).$mount('#app')
